import { FC, MouseEvent } from 'react';
import { useNavigate } from 'react-router-dom';

import { ILesson } from 'api/api-courses';
import schoolPNG from 'assets/images/png/school.png';
import styles from './LessonCard.module.css';

import { IconCopyLink, IconPen } from 'components/atoms/icons';
import { creatLinkToCourseVocabularyLesson } from 'consts';
import { notifySuc } from 'helpers/notification';

interface LessonCardProps {
  courseId: string;
  moduleId?: string;
  moduleOrder?: number;
  moduleTitle?: string;
  lesson: ILesson;
  index: number;
}

const LessonCard: FC<LessonCardProps> = ({
  courseId,
  moduleId,
  moduleOrder,
  moduleTitle,
  lesson: { id: lessonId, imageUrl, title, wordsCount, subtitle, order }
}) => {
  const navigate = useNavigate();

  const goToLessonWordsPage = () => {
    if (moduleId && moduleOrder && moduleTitle) {
      navigate(
        `/dictionaries/${courseId}/${moduleId}/${lessonId}?moduleOrder=${moduleOrder}&moduleTitle=${moduleTitle}`
      );
    } else {
      navigate(`/dictionaries/${courseId}/no-module/${lessonId}`);
    }
  };

  const handleCopyLinkToClipboard = (event: MouseEvent<HTMLButtonElement>) => {
    event.stopPropagation();
    navigator.clipboard
      .writeText(creatLinkToCourseVocabularyLesson({ courseId, moduleId, lessonId }))
      .then(() => notifySuc('Ссылка скопирована'));
  };

  return (
    <li
      className={styles.container}
      onClick={e => {
        e.stopPropagation();
        goToLessonWordsPage();
      }}
    >
      <div className={styles.image}>
        <img className='object-cover' src={imageUrl || schoolPNG} alt={imageUrl} />
      </div>

      <div className={styles.text}>
        <h5 className={styles.heading}>{subtitle || `Урок ${order}`}</h5>
        <h3 className={styles.name}>{title}</h3>
      </div>

      <span className='mr-[32px] text-[16px] font-[500] leading-[19px] text-[#71798F]'>
        {wordsCount}
      </span>

      <button
        title='Скопировать ссылку на урок'
        className='mr-[32px]'
        onClick={handleCopyLinkToClipboard}
      >
        <IconCopyLink />
      </button>

      <button className={styles.buttonEdit}>
        <IconPen color='#71798F' />
      </button>
    </li>
  );
};

export default LessonCard;
